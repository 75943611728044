var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-card",
                {
                  staticClass: "mt-4",
                  attrs: {
                    header:
                      "Label - " +
                      _vm.label.Name +
                      " (" +
                      _vm.label.LabelID +
                      ")"
                  }
                },
                [
                  _c("table", { staticClass: "table table-bordered" }, [
                    _c("tr", [
                      _c("th", [_vm._v("Naam")]),
                      _c("td", [_vm._v(_vm._s(_vm.label.Name))]),
                      _c("th", [_vm._v("Status")]),
                      _c("td", [_vm._v(_vm._s(_vm.label.Status))])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Contract")]),
                      _c(
                        "td",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "contract-detail",
                                  params: { id: _vm.label.ContractID }
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.label.Contract.Name) +
                                  " (" +
                                  _vm._s(_vm.label.Contract.ContractID) +
                                  ") "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("th", [_vm._v("Royalty")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("numberFormat")(_vm.label.RoyaltyPerc)
                          ) + " %"
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Parent Label")]),
                      _c(
                        "td",
                        [
                          _vm.label.ParentLabelID != null
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "label-detail",
                                      params: { id: _vm.label.ParentLabelID }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.label.ParentLabel.Name) +
                                      " (" +
                                      _vm._s(_vm.label.ParentLabelID) +
                                      ") "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("th", [_vm._v("Royalty Streaming")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("numberFormat")(
                              _vm.label.RoyaltyPercStreaming
                            )
                          ) + " %"
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Aangemaakt")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("datetimeFormat")(_vm.label.Created))
                        )
                      ]),
                      _c("th", [_vm._v("Gewijzigd")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("datetimeFormat")(_vm.label.Modified))
                        )
                      ])
                    ])
                  ])
                ]
              ),
              false
                ? _c(
                    "b-card",
                    {
                      staticClass: "mt-4",
                      attrs: { header: "Transaction Matches" }
                    },
                    [_c("trx-match-list", { attrs: { label: _vm.label } })],
                    1
                  )
                : _vm._e(),
              _c(
                "b-card",
                { staticClass: "mt-4", attrs: { header: "Sub Labels" } },
                [
                  _c(
                    "i",
                    {
                      staticStyle: {
                        "font-weight": "bold",
                        "font-size": "18px",
                        color: "#bf0000"
                      }
                    },
                    [_vm._v("»")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        to: {
                          name: "label-detail-edit",
                          params: {
                            id: "new",
                            ContractID: _vm.label.ContractID,
                            ParentLabelID: _vm.label.LabelID
                          }
                        }
                      }
                    },
                    [_vm._v("Nieuw label aanmaken...")]
                  ),
                  _c("label-list", {
                    staticClass: "mt-4",
                    attrs: { filter: { ParentLabelID: _vm.label.LabelID } }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }