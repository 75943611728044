<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card :header="'Label - ' + label.Name + ' (' + label.LabelID + ')'" class="mt-4">

          <table class="table table-bordered">
            <tr>
              <th>Naam</th><td>{{ label.Name }}</td>
              <th>Status</th><td>{{ label.Status }}</td>
            </tr>
            <tr>
              <th>Contract</th>
              <td>
                <router-link :to="{ name: 'contract-detail', params: { id: label.ContractID } }">
                  {{ label.Contract.Name }} ({{ label.Contract.ContractID }})
                </router-link>
              </td>
              <th>Royalty</th><td>{{ label.RoyaltyPerc|numberFormat }} %</td>
            </tr>
            <tr>
              <th>Parent Label</th>
              <td>
                <router-link v-if="label.ParentLabelID != null" :to="{ name: 'label-detail', params: { id: label.ParentLabelID } }">
                  {{ label.ParentLabel.Name }} ({{ label.ParentLabelID }})
                </router-link>
              </td>
              
              <th>Royalty Streaming</th><td>{{ label.RoyaltyPercStreaming|numberFormat }} %</td>
            </tr>
            <tr>
              <th>Aangemaakt</th><td>{{ label.Created|datetimeFormat }}</td>
              <th>Gewijzigd</th><td>{{ label.Modified|datetimeFormat }}</td>
            </tr>
          </table>
        </b-card>

        <b-card header="Transaction Matches" class="mt-4" v-if="false">
          <trx-match-list :label="label" />
        </b-card>

        <b-card header="Sub Labels" class="mt-4">
          <i style="font-weight:bold; font-size:18px ;color:#bf0000">»</i>
          <router-link :to="{ name: 'label-detail-edit', params: { id: 'new', ContractID: label.ContractID, ParentLabelID: label.LabelID } }" class="ml-2">Nieuw label aanmaken...</router-link>

          <label-list class="mt-4" :filter="{ ParentLabelID: label.LabelID }" />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import LabelList from '@/components/LabelList.vue'

  export default {
    name: "LabelDetail",
    components: {
      LabelList,
      'trx-match-list': () => import('@/components/TrxMatchList'),
    },
    data() {
      return {
      };
    },
    props: {
      label: Object,
    },
    watch: {
    },
    mounted() {
      this.$root.$data.crumbs = [
        { to: { name: "label-search" }, text: "Labels" },
        { text: this.label.Name },
      ];
    }
  };
</script>

<style lang="scss" scoped>

</style>